export const menuItems = [
  {
    path: "/",
    label: "Home",
  },
  {
    path: "/Misi",
    label: "Misi",
  },
  {
    path: "/faq",
    label: "FAQ",
  },
  {
    path: "/Kontak",
    label: "Kontak",
  },
  {
    path: "/Merch",
    label: "Merch",
  }
];
